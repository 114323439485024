<template>
  <div class="bigDIv" ref="bigDiv">
    <img
      class="topImg"
      src="https://oss.hshwhkj.com/hshcrm/image/2024/12/10/173382134144691626.jpg"
      alt=""
    />
    <div class="question">
      <div class="divs">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          您好!我是养生老师的助理便于分配专业老师针对性的为您服务，下面问您几个小问题。
        </div>
      </div>
      <div class="divs">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          答题后可免费领取课程!
        </div>
      </div>
      <div class="divs">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          <div>您今年多大年龄了？</div>
          <div class="rightItem">
            <div
              class="item"
              v-for="item in questionA"
              :key="item.id"
              @click="selectAge(item.id)"
              :class="{ itemActive: questionAFlage == item.id }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="divs" v-if="questionAFlage !== ''">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          <div>您是否有健康调理经验？</div>
          <div class="rightItem">
            <div
              class="item"
              v-for="item in questionB"
              :key="item.id"
              @click="selectexperience(item.id)"
              :class="{ itemActive: questionBFlage == item.id }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="divs" v-if="questionBFlage !== ''">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          <div>您学习健康食补养生课程的目的是什么？</div>
          <div class="rightItem">
            <div
              class="item"
              v-for="item in questionC"
              :key="item.id"
              @click="selectexGoal(item.id)"
              :class="{ itemActive: questionCFlage == item.id }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="divs" v-if="questionCFlage !== ''">
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          <div>本次课程名额有限，您能坚持学习吗？</div>
          <div class="rightItem">
            <div
              class="item"
              v-for="item in questionD"
              :key="item.id"
              @click="selectend(item.id)"
              :class="{ itemActive: questionDFlage == item.id }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="divs"
        v-if="questionDFlage !== ''"
        style="margin-bottom: 120px;"
      >
        <div class="left">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/12/11/173388177385398600.png"
            alt=""
          />
        </div>
        <div class="right">
          恭喜您！已为您申请专属免费直播课及资料！尽快领取哦！祝您和家人身体健康！
        </div>
      </div>
    </div>
    <div class="btnfixd" v-if="questionDFlage !== ''" @click="throttleSubmit">
      立即添加营养师领课
    </div>
  </div>
</template>

<script>
import { getWxCsad } from "../../api/360AD.js";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      lastClickTime: 0,
      questionAFlage: "",
      questionBFlage: "",
      questionCFlage: "",
      questionDFlage: "",
      questionA: [
        {
          id: 1,
          name: "50-60岁",
        },
        {
          id: 2,
          name: "61-70岁",
        },
        {
          id: 3,
          name: "71-75岁",
        },
        {
          id: 4,
          name: "75岁以上",
        },
      ],
      questionB: [
        {
          id: 1,
          name: "没有",
        },
        {
          id: 2,
          name: "自己调理",
        },
        {
          id: 3,
          name: "定期体检",
        },
        {
          id: 4,
          name: "专业营养师调理",
        },
      ],
      questionC: [
        {
          id: 1,
          name: "身材管理",
        },
        {
          id: 2,
          name: "调理身体",
        },
        {
          id: 3,
          name: "增加体质",
        },
        {
          id: 4,
          name: "家庭饮食搭配",
        },
      ],
      questionD: [
        {
          id: 1,
          name: "能",
        },
        {
          id: 2,
          name: "不能",
        },
      ],
    };
  },
  methods: {
    selectAge(e) {
      this.questionAFlage = e;

      // 获取滚动容器
      setTimeout(() => {
        const container = this.$refs.bigDiv;
        if (container) {
          container.scrollTo({
            top: container.scrollTop + 500, // 当前滚动位置 + 500px
            behavior: "smooth",
          });
        } else {
          console.error("滚动容器未找到");
        }
      }, 800);
    },
    selectexperience(e) {
      this.questionBFlage = e;
      // 获取滚动容器
      setTimeout(() => {
        const container = this.$refs.bigDiv;
        if (container) {
          container.scrollTo({
            top: container.scrollTop + 500, // 当前滚动位置 + 500px
            behavior: "smooth",
          });
        } else {
          console.error("滚动容器未找到");
        }
      }, 800);
    },
    selectexGoal(e) {
      this.questionCFlage = e;
      // 获取滚动容器
      setTimeout(() => {
        const container = this.$refs.bigDiv;
        if (container) {
          container.scrollTo({
            top: container.scrollTop + 500, // 当前滚动位置 + 500px
            behavior: "smooth",
          });
        } else {
          console.error("滚动容器未找到");
        }
      }, 800);
    },
    selectend(e) {
      // 获取滚动容器
      setTimeout(() => {
        const container = this.$refs.bigDiv;
        if (container) {
          container.scrollTo({
            top: container.scrollTop + 500, // 当前滚动位置 + 500px
            behavior: "smooth",
          });
        } else {
          console.error("滚动容器未找到");
        }
      }, 400);
      this.questionDFlage = e;
    },
    throttleSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick();
    },
    async wxClick() {
      const rules = JSON.parse(sessionStorage.getItem("ruleForm"));
      const { data } = await getWxCsad(rules);
      console.log(data);
      window.location.href = data.data;
    },
    // addNutri() {
    //   this.$router.replace({
    //     path: "/txQrcode",
    //   });
    // },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDIv {
  overflow: hidden;
  height: 100%;
  background-color: #eeeeee;
  overflow-y: scroll;
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  .btnfixd {
    position: fixed;
    width: 355px;
    height: 45px;
    line-height: 45px;
    background-color: red;
    text-align: center;
    border-radius: 5px;
    left: 2%;
    font-size: 20px;
    font-weight: 700;
    bottom: 10px;
    color: #ffffff;
    animation: heartbeat 2s infinite;
  }

  .topImg {
    width: 375px;
    height: 220px;
    position: fixed;
    top: 0;
  }
  .question {
    width: 100%;
    margin-top: 230px;
    background-color: #eeeeee;

    .divs {
      display: flex;
      margin: 20px 10px;
      overflow: hidden;
      .left {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .right {
        margin-left: 10px;
        max-width: 300px;
        padding: 5px;
        line-height: 1.5rem;
        background-color: #ffffff;
        border-radius: 5px;
        font-size: 18px;
        .rightItem {
          display: flex;
          width: 300px;
          border-radius: 20px;
          flex-wrap: wrap;
          .item {
            width: 120px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #7d94f2;
            border: #7d94f2 2px solid;
            font-size: 16px;
            margin: 5px 10px;
            border-radius: 10px;
            font-weight: 700;
          }
          .itemActive {
            background-color: #28b881;
            color: #fff;
            border: none;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDIv {
    overflow: hidden;
    width: 375px;
    margin: 0 auto;
    height: 100%;
    background-color: #eeeeee;
    overflow-y: scroll;
    .btnfixd {
      position: fixed;
      width: 355px;
      height: 45px;
      line-height: 45px;
      background-color: red;
      text-align: center;
      border-radius: 5px;
      left: 50%;
      bottom: 10px;
      transform: translate(-50%);
      color: #ffffff;
    }

    .topImg {
      width: 375px;
      height: 220px;
      position: fixed;
      top: 0;
    }
    .question {
      width: 100%;
      margin-top: 230px;
      background-color: #eeeeee;

      .divs {
        display: flex;
        margin: 20px 10px;
        overflow: hidden;
        .left {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
          }
        }
        .right {
          margin-left: 10px;
          max-width: 280px;
          padding: 10px;
          line-height: 1.5rem;
          background-color: #ffffff;
          border-radius: 5px;
          font-size: 18px;
          .rightItem {
            display: flex;
            width: 280px;

            flex-wrap: wrap;
            .item {
              width: 110px;
              text-align: center;
              color: #7d94f2;
              border: #7d94f2 2px solid;
              font-size: 14px;
              margin: 10px;
              border-radius: 10px;
              font-weight: 700;
            }
            .itemActive {
              background-color: #d3e1fb;
            }
          }
        }
      }
    }
  }
}
</style>
