import { render, staticRenderFns } from "./txCfoodQu.vue?vue&type=template&id=38f4660c&scoped=true"
import script from "./txCfoodQu.vue?vue&type=script&lang=js"
export * from "./txCfoodQu.vue?vue&type=script&lang=js"
import style0 from "./txCfoodQu.vue?vue&type=style&index=0&id=38f4660c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f4660c",
  null
  
)

export default component.exports